//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-152:_100,_84,_912,_632,_812,_16,_340,_644;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-152')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-152', "_100,_84,_912,_632,_812,_16,_340,_644");
        }
      }catch (ex) {
        console.error(ex);
      }